
import { Vue, Options } from "vue-class-component";
import ellipsisString  from "@/app/infrastructures/misc/common-library/EllipsisString";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import formatValueNumber from "@/app/infrastructures/misc/common-library/FormatValueNumber";
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import router from "../../router";
import { CargoController } from "../../controllers/CargoController";
import { NGenController } from "../../controllers/NGenController";
import { CARGO } from "@/app/infrastructures/misc/RolePermission";
import { CargoData } from "@/domain/entities/Cargo";
import { capitalize } from "@vue/shared";
import { QueryParamsEntities2 } from "@/domain/entities/MainApp";
import { ReadyToCargoController } from "../../controllers/ReadyToCargoController";
import { convertDecimalWithComma } from "@/app/infrastructures/misc/Utils";

@Options({
  components: {},
  computed: {
    ableToViewDetail() {
      return checkRolePermission(CARGO.DETAIL);
    }
  }
})
export default class TabBookingCanceled extends Vue {
  mounted() {
    CargoController.setFirstPage();
    CargoController.setCargoStatus("cancel,failed");
    this.fetchCargoList("retry");
  }
  unmounted() {
    CargoController.onReset();
  }

  fetchCargoList(type: string) {
    CargoController.fetchCargoList({ type });
  }

  deleteCargo() {
    CargoController.deleteCargo();
    this.handlerModalDeleteBooking(false);
  }

  // loading
  get isLoading() {
    return CargoController.isLoading;
  }

  // error cause
  get errorCause() {
    return CargoController.errorCause;
  }

  showModalDeleteBooking = false;
  handlerModalDeleteBooking(val: boolean) {
    this.showModalDeleteBooking = val;
  }

  showModalReBooking = false;
  tempDataReBooking: any = [];
  handlerModalReBooking(val: boolean, data?: any) {
    this.tempDataReBooking = data;
    this.showModalReBooking = val;
  }

  formatValueNumber(number: number) {
    return formatValueNumber(number);
  }

  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-12 text-left whitespace-no-wrap align-top",
        render: (_: any, index: any) => {
          return `<div class="overflow-ellipsis text-left">${index +
            1 +
            this.pagination.limit * (this.pagination.page - 1)}</div>`;
        }
      },
      {
        name: "No. Kargo",
        styleHead: "w-56 text-left whitespace-no-wrap align-top break-all",
        render: (item: CargoData) => {
          return `<p class="w-full break-all text-left">${this.cargoColumnData(item.bookingRequestId, item.cargoNumber)}</p>`;
        }
      },
      {
        name: "Kota Asal",
        styleHead: "w-56 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${this.cityColumnData(item.originCityCode, item.originCityName)}</div>`;
        }
      },
      {
        name: "Kota Tujuan",
        styleHead: "w-56 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${this.cityColumnData(item.destinationCityCode, item.destinationCityName)}</div>`;
        }
      },
      {
        name: "Alasan Gagal",
        styleHead: "w-64 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left text-red-lp-100">${this.reasonColumnData(item, false)}</div>`;
        },
        hideIconTooltip: true,
        toolTip: (item: any) => {
            return `<div class='whitespace-normal w-48 font-montserrat text-black-lp-300 text-14px'>${this.reasonColumnData(item, true)}</div>`;
        },
      },
      {
        name: "Kode Bandara Tujuan",
        styleHead: "w-64 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${this.destinationAirportCode(
            item
          )}</div>`;
        }
      },
      {
        name: "No. Seri Kendaraan",
        styleHead: "w-40 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${this.vehiclePoliceNumberColumnData(item.vehiclePoliceNumber)}</div>`;
        }
      },
      {
        name: "Tipe Kargo",
        styleHead: "w-40 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${this.cargoTypeColumnData(
            item.cargoType
          )}</div>`;
        }
      },
      {
        name: "Total STT",
        styleHead: "w-28 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${this.roundedNumberColumnData(item.totalStt)}</div>`;
        }
      },
      {
        name: "Total Koli",
        styleHead: "w-28 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${this.roundedNumberColumnData(item.totalPieces)}</div>`;
        }
      },
      {
        name: "Total Berat Kotor</br>Kargo Aktual",
        styleHead: "w-40 text-center whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${this.formatValueNumber(
            item.totalActualCargoGrossWeight
          )} Kg</div>`;
        }
      },
      {
        name: "Total Berat Dimensi</br>Kargo Aktual",
        styleHead: "w-40 text-center whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${this.formatValueNumber(
            item.totalActualCargoVolumeWeight
          )} Kg</div>`;
        }
      },
      {
        name: "Tanggal Dibuat",
        styleHead: "w-56 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="flex flex-col"><div class="overflow-ellipsis text-left">${this.dateColumnData(
            item.createdAt, true
          )}<div class="truncate text-gray-lp-600 w-32">${
            item.createdBy || "-"
          }</div></div>`;
        }
      },
      {
        name: "Tanggal Diubah",
        styleHead: "w-56 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="flex flex-col"><div class="overflow-ellipsis text-left">${this.dateColumnData(
            item.updatedAt, false
          )}<div class="truncate text-gray-lp-600 w-32">${
            item.updatedBy || "-"
          }</div></div>`;
        }
      },
      {
        name: "Action",
        key: "actionable_column",
        styleHead: "w-28 text-left align-top",
        showButton: () => {
          return true;
        }
      }
    ];
  }

  destinationAirportCode(item: CargoData): string {
    return item.cargoType === "plane"
      ? `${item.airportDestinationCityCode} - ${item.airportDestinationCityName}`
      : "-";
  }

  actionGroupOptions = (item: any) => {
    return [
      {
        icon: "refresh-disable",
        label: "Booking Ulang",
        clickFunction: (e: any) => this.handlerModalReBooking(true, e)
      },
      {
        icon: "trash",
        label: "Hapus",
        clickFunction: () => {
          CargoController.setCargoIdToCancelOrDelete(item.id);
          CargoController.setCargoNoForModal(item.cargoNumber);
          this.handlerModalDeleteBooking(true);
        }
      }
    ];
  };

  goToReBooking() {
    const bookingQueryObject = {
      autofill: true,
      rcId: this.tempDataReBooking.id
    };
    const bookingQuery = new QueryParamsEntities2(bookingQueryObject);
    router.push(`/cargo/create?${bookingQuery.queryString}`);
    ReadyToCargoController.setFlowBooking("rebooking")
    CargoController.setIsDeleteStt(false);
    this.handlerModalReBooking(false, []);
  }

  cargoColumnData(bookingRequestId: string, cargoNumber: string, isForUrl?: boolean) {
    const cargoNumberData = bookingRequestId ? bookingRequestId : cargoNumber
    if (isForUrl) {
      return cargoNumberData
    }
    return ellipsisString(cargoNumberData, 40)
  }

  cityColumnData(cityCode: string, cityName: string) {
    return cityCode && cityName ? `${cityCode} - ${cityName}` :  "-"
  }

  reasonColumnData(item: CargoData, isForTooltip: boolean) {
    const isReasonExist = item.reasonFailed ? item.reasonFailed : "-"
    let reasonData = isReasonExist

    if (item.isFromRtc && item.cargoType === "plane") {
      reasonData = !item.bookingRequestId ? "Gagal mendapatkan response nGen" : isReasonExist
    }

    return !isForTooltip ? ellipsisString(reasonData, 50) : reasonData
  }

  vehiclePoliceNumberColumnData(number: string) {
    return number !== "-" ? number : "-"
  }

  cargoTypeColumnData(type: string) {
    return type ? capitalize(type) : "-"
  }

  roundedNumberColumnData(number: number) {
    return number ? number.toString() : "-"
  }

  decimalNumberColumnData(number: number | string) {
    const decimalNumber = convertDecimalWithComma(number, 2)
    return decimalNumber !== 0 ? `${decimalNumber} Kg` : "-"
  }

  dateColumnData(date: string, isWithTime: boolean) {
    if (!date) {
      return "-"
    }
    return isWithTime ? formatDate(date) : formatDateWithoutTime(date)
  }

  async downloadEPL(id: number) {
    await CargoController.getDownloadData({ id: id, type: "epl" });
  }

  async downloadManifest(id: number) {
    await CargoController.getDownloadData({ id: id, type: "cargo" });
  }

  printAWBLionCargo(AWBNo: number) {
    NGenController.printAWBLionCargo({ AWBNo: AWBNo });
  }

  // Print
  check = null;
  refs: any = "";
  cargoNumber = "";

  chevronUp: boolean[] = [];

  get pagination() {
    return CargoController.retryCargo.pagination;
  }

  get cargoList() {
    return CargoController.retryCargo.data;
  }

  get cargoListAvailable() {
    return (
      !this.cargoList.length && !this.isLoading && this.pagination.page === 1
    );
  }

  // detail
  onClickRow(item: CargoData) {
    router.push(
      `/cargo/${encodeURIComponent(this.cargoColumnData(item.bookingRequestId, item.cargoNumber, true))}?rc_no=${item.id}`
    );
  }
}
