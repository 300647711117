
import { Vue, Options } from "vue-class-component";
import { ellipsisString } from "@/app/infrastructures/misc/Utils";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import formatValueNumber from "@/app/infrastructures/misc/common-library/FormatValueNumber";
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import router from "../../router";
import { CargoController } from "../../controllers/CargoController";
import { NGenController } from "../../controllers/NGenController";
import { CARGO } from "@/app/infrastructures/misc/RolePermission";
import { CargoData } from "@/domain/entities/Cargo";
import { capitalize } from "@vue/shared";
import Print from "./modules/print.vue";

@Options({
  components: { Print },
  computed: {
    ableToViewDetail() {
      return checkRolePermission(CARGO.DETAIL);
    }
  }
})
export default class TabBookingInProgress extends Vue {
  mounted() {
    this.refs = this.$refs;
    CargoController.setFirstPage();
    CargoController.setCargoStatus("process-immediate,process,process-immediate-tracking,process-webhook");
    this.fetchCargoList("retry");
  }
  unmounted() {
    CargoController.onReset();
  }

  fetchCargoList(type: string) {
    CargoController.fetchCargoList({ type });
  }

  cancelCargo() {
    CargoController.cancelCargo();
    this.handlerModalCancelBooking(false);
  }

  // loading
  get isLoading() {
    return CargoController.isLoading;
  }

  // error cause
  get errorCause() {
    return CargoController.errorCause;
  }

  showModalCancelBooking = false;
  handlerModalCancelBooking(val: boolean) {
    this.showModalCancelBooking = val;
  }
  formatValueNumber(number: number) {
    return formatValueNumber(number);
  }

  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-12 text-left whitespace-no-wrap align-top",
        render: (_: any, index: any) => {
          return `<div class="overflow-ellipsis text-left">${index +
            1 +
            this.pagination.limit * (this.pagination.page - 1)}</div>`;
        }
      },
      {
        name: "No. Kargo",
        styleHead: "w-56 text-left whitespace-no-wrap align-top break-all",
        render: (item: CargoData) => {
          return `<p class="w-full break-all text-left">${this.convertCargoNo(item.bookingRequestId, item.cargoNumber).converted}</p>`;
        }
      },
      {
        name: "Kota Asal",
        styleHead: "w-56 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${this.cityColumnData(item.originCityCode, item.originCityName)}</div>`;
        }
      },
      {
        name: "Kota Tujuan",
        styleHead: "w-56 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${this.cityColumnData(item.destinationCityCode,item.destinationCityName)}</div>`;
        }
      },
      {
        name: "Kode Bandara Tujuan",
        styleHead: "w-64 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${this.airportDestinationCode(
            item
          )}</div>`;
        }
      },
      {
        name: "No. Seri Kendaraan",
        styleHead: "w-40 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${item.vehiclePoliceNumber || "-"}</div>`;
        }
      },
      {
        name: "Tipe Kargo",
        styleHead: "w-40 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${this.cargoTypeColumnData(item.cargoType)}</div>`;
        }
      },
      {
        name: "Total STT",
        styleHead: "w-28 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${item.totalStt}</div>`;
        }
      },
      {
        name: "Total Koli",
        styleHead: "w-28 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${item.totalPieces}</div>`;
        }
      },
      {
        name: "Total Berat Kotor</br>Kargo Aktual",
        styleHead: "w-40 text-center whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${this.formatValueNumber(
            item.totalActualCargoGrossWeight
          )} Kg</div>`;
        }
      },
      {
        name: "Total Berat Dimensi</br>Kargo Aktual",
        styleHead: "w-40 text-center whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${this.formatValueNumber(
            item.totalActualCargoVolumeWeight
          )} Kg</div>`;
        }
      },
      {
        name: "Tanggal Dibuat",
        styleHead: "w-56 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="flex flex-col"><div class="overflow-ellipsis text-left">${formatDate(
            item.createdAt
          )}<div class="truncate text-gray-lp-600 w-32">${
            item.createdBy || "-"
          }</div></div>`;
        }
      },
      {
        name: "Tanggal Diubah",
        styleHead: "w-56 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="flex flex-col"><div class="overflow-ellipsis text-left">${formatDateWithoutTime(
            item.updatedAt
          )}<div class="truncate text-gray-lp-600 w-32">${
            item.updatedBy || "-"
          }</div></div>`;
        }
      },
      {
        name: "Action",
        key: "actionable_column",
        styleHead: "w-28 text-left align-top",
        showButton: () => {
          return true;
        }
      }
    ];
  }

  airportDestinationCode(item: CargoData) {
    return item.cargoType === "plane"
      ? `${item.airportDestinationCityCode} - ${item.airportDestinationCityName}`
      : "-";
  }

  actionGroupOptions = (item: any) => {
    return [
      {
        icon: "x-circle-outline-gray",
        label: "Batalkan",
        clickFunction: () => {
          CargoController.setCargoIdToCancelOrDelete(item.id);
          CargoController.setCargoNoForModal(item.cargoNumber);
          this.handlerModalCancelBooking(true);
        },
      },
    ];
  };

  convertCargoNo(bookingRequestId: string, cargoNumber: string) {
    const result = bookingRequestId ? bookingRequestId : cargoNumber;
    return { converted: ellipsisString(result, 40) , result };
  }

  cityColumnData(cityCode: string, cityName: string) {
    return cityCode && cityName ? `${cityCode} - ${cityName}` :  "-"
  }

  cargoTypeColumnData(type: string) {
    return type ? capitalize(type) : "-"
  }

  async downloadEPL(id: number) {
    await CargoController.getDownloadData({ id: id, type: "epl" });
  }

  async downloadManifest(id: number) {
    await CargoController.getDownloadData({ id: id, type: "cargo" });
  }

  printAWBLionCargo(AWBNo: number) {
    NGenController.printAWBLionCargo({ AWBNo: AWBNo });
  }

  // Print
  check = null;
  refs: any = "";
  cargoNumber = "";

  chevronUp: boolean[] = [];

  get pagination() {
    return CargoController.retryCargo.pagination;
  }

  get cargoList() {
    return CargoController.retryCargo.data;
  }

  get cargoListAvailable() {
    return (
      !this.cargoList.length && !this.isLoading && this.pagination.page === 1
    );
  }

  // detail
  onClickRow(item: CargoData) {
    router.push(
      `/cargo/${encodeURIComponent(this.convertCargoNo(item.bookingRequestId, item.cargoNumber).result)}?rc_no=${item.id}`
    );
  }
}
