
import { Vue, Options } from "vue-class-component";
import { ellipsisString } from "@/app/infrastructures/misc/Utils";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import formatValueNumber from "@/app/infrastructures/misc/common-library/FormatValueNumber";
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import router from "../../router";
import { CargoController } from "../../controllers/CargoController";
import { NGenController } from "../../controllers/NGenController";
import { CARGO } from "@/app/infrastructures/misc/RolePermission";
import { CargoData } from "@/domain/entities/Cargo";
import { capitalize } from "@vue/shared";
import Print from "./modules/print.vue";

@Options({
  components: { Print },
  computed: {
    ableToViewDetail() {
      return checkRolePermission(CARGO.DETAIL);
    }
  }
})
export default class TabBookingSuccess extends Vue {
  mounted() {
    this.refs = this.$refs;
    CargoController.setCargoStatus("");
    this.fetchCargoList();
  }
  unmounted() {
    CargoController.onReset();
  }

  fetchCargoList() {
    CargoController.fetchCargoList();
  }

  // loading
  get isLoading() {
    return CargoController.isLoading;
  }

  // error cause
  get errorCause() {
    return CargoController.errorCause;
  }

  formatValueNumber(number: number) {
    return formatValueNumber(number);
  }

  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-12 text-left whitespace-no-wrap align-top",
        render: (_: any, index: any) => {
          return `<div class="overflow-ellipsis text-left">${index +
            1 +
            this.pagination.limit * (this.pagination.page - 1)}</div>`;
        }
      },
      {
        name: "No. Kargo",
        styleHead: "w-56 text-left whitespace-no-wrap align-top break-all",
        render: (item: CargoData) => {
          return `<p class="w-full break-all text-left">${ellipsisString(
            item.cargoNumber,
            40
          )}</p>`;
        }
      },
      {
        name: this.$t("Kota Asal"),
        styleHead: "w-56 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${item.originCityCode} - ${item.originCityName}</div>`;
        }
      },
      {
        name: this.$t("Kota Tujuan"),
        styleHead: "w-56 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${item.destinationCityCode} - ${item.destinationCityName}</div>`;
        }
      },
      {
        name: this.$t("Kode Bandara Tujuan"),
        styleHead: "w-64 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${this.airportDestinationCode(
            item
          )}</div>`;
        }
      },
      {
        name: this.$t("No. Seri Kendaraan"),
        styleHead: "w-40 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${ellipsisString(item.vehiclePoliceNumber, 14)}</div>`;
        }
      },
      {
        name: this.$t("Tipe Kargo"),
        styleHead: "w-40 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${capitalize(
            item.cargoType
          )}</div>`;
        }
      },
      {
        name: "Total STT",
        styleHead: "w-28 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${item.totalStt}</div>`;
        }
      },
      {
        name: this.$t("Total Koli"),
        styleHead: "w-28 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${item.totalPieces}</div>`;
        }
      },
      {
        name: this.$t("Total Berat Kotor</br>Kargo Aktual"),
        styleHead: "w-40 text-center whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${this.formatValueNumber(
            item.totalActualCargoGrossWeight
          )} Kg</div>`;
        }
      },
      {
        name: this.$t("Total Berat Dimensi</br>Kargo Aktual"),
        styleHead: "w-40 text-center whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${this.formatValueNumber(
            item.totalActualCargoVolumeWeight
          )} Kg</div>`;
        }
      },
      {
        name: this.$t("Tanggal Dibuat"),
        styleHead: "w-56 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="flex flex-col"><div class="overflow-ellipsis text-left">${formatDate(
            item.createdAt
          )}<div class="truncate text-gray-lp-600 w-32">${
            item.createdBy
          }</div></div>`;
        }
      },
      {
        name: this.$t("Tanggal Diubah"),
        styleHead: "w-56 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="flex flex-col"><div class="overflow-ellipsis text-left">${formatDateWithoutTime(
            item.updatedAt
          )}<div class="truncate text-gray-lp-600 w-32">${
            item.updatedBy
          }</div></div>`;
        }
      },
      {
        name: "Action",
        key: "actionable_column",
        styleHead: "w-28 text-left align-top",
        showButton: () => true
      }
    ];
  }

  airportDestinationCode(item: CargoData) {
    return item.cargoType === "plane"
      ? `${item.airportDestinationCityCode} - ${item.airportDestinationCityName}`
      : "-";
  }

  actionGroupOptions = (item: any) => {
    const groupOptions = [
      {
        icon: "printer",
        label: "AWB Cargo Genesis",
        clickFunction: () => {
          this.refs.print.printManifestV2(item.cargoNumber);
          this.cargoNumber = item.cargoNumber;
        }
      },
      {
        icon: "invoice",
        label: "Print Label",
        clickFunction: () => {
          this.refs.print.printData(item.cargoNumber, "label");
          this.cargoNumber = item.cargoNumber;
        }
      },
      {
        icon: "document-download-outline-grey",
        label: "Download Excel",
        clickFunction: () => {
          this.downloadManifest(item.id);
        }
      },
      {
        icon: "printer",
        label: "AWB Lion Cargo",
        clickFunction: () => {
          this.printAWBLionCargo(item.cargoNumber);
        }
      },
      {
        icon: "download-gray",
        label: "Download EPL",
        clickFunction: () => {
          this.downloadEPL(item.id);
        }
      }
    ];
    if (
      item.cargoType === "plane" &&
      (item.originCityCode === "BTH" || item.destinationCityCode === "BTH")
    ) {
      return groupOptions;
    } else if (item.cargoType === "plane") {
      return groupOptions.slice(0, 4);
    }
    return groupOptions.slice(0, 3);
  };

  errorDownloadEpl = false;
  async downloadEPL(id: number) {
    await CargoController.getDownloadData({ id: id, type: "epl" });
  }

  async downloadManifest(id: number) {
    await CargoController.getDownloadData({ id: id, type: "cargo" });
  }

  printAWBLionCargo(AWBNo: number) {
    NGenController.printAWBLionCargo({ AWBNo: AWBNo });
  }

  // Print
  check = null;
  refs: any = "";
  cargoNumber = "";

  async printManifest(item: CargoData) {
    this.refs.print.printData(item.cargoNumber, "manifest");
    this.cargoNumber = item.cargoNumber;
  }

  async onPrintLabel(item: CargoData) {
    this.refs.print.printData(item.cargoNumber, "label");
    this.cargoNumber = item.cargoNumber;
  }

  chevronUp: boolean[] = [];

  get pagination() {
    return CargoController.cargoData.pagination;
  }

  get cargoList() {
    return CargoController.cargoData.data;
  }

  get cargoListAvailable() {
    return (
      !this.cargoList.length && !this.isLoading && this.pagination.page === 1
    );
  }

  // detail
  onClickRow(item: CargoData) {
    router.push(`/cargo/${encodeURIComponent(item.cargoNumber)}`);
  }
}
